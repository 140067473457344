<template>
  <section>
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">AWARDS & GRANTS</h2>
          <h2 class="banner-sub-title"></h2>
        </div>
      </div>
    </div>
    <section class="main-container">
      <div class="container">
        <div class="left">
          <div class="div">
            <div class="widget">
              <h3 class="widget-title"><span id="main-title-name">AWARDS & GRANTS</span></h3>
              <ul class="nav-tabs">
                <li :class="$route.name===nav.urlName ? 'active':'' " v-for="(nav, index) in awardNav" :key="index"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <router-view></router-view>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name:'about',
  data() {
    return {
      awardNav: [
        {title: "Furuse & AATS Foundation Award", urlName: "aats" },
        {title: "\"Oriental Rising Star\" Award", urlName: "ors" }
      ]
    }
  },
  methods: {
    redirectUrl(nav) {
      this.$router.push({name: nav.urlName})
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.main-container {
  .container {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 15px;
      .div {
        height: 100%;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        background-color: #f8f9fa;
        .widget {
          padding: 30px 15px 40px;
          position: sticky;
          top: 70px;
          .widget-title{
            font-size: 27px;
            font-weight: 900;
            position: relative;
            padding-left: 30px;
            margin: 0 -15px;
            background-color: #19317e;
            color: #fff;
            text-transform: uppercase;
          }
          .nav-tabs li {
            color: #444444;
            line-height: normal;
            a {
              position: relative;
              color: #444444;
              padding: 15px 0 15px 15px;
              font-weight: 600;
              border-bottom: 1px solid #ddd;
              display: block;
              transition: all .3s;
              font-size: 18px;
            }
            &:hover a{
              color: #1B3287;
            }
            &.active a {
              color: #1B3287;
              &::before {
                font-family: "iconfont";
                content:'\e6ae';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .right {
      min-height: 700px;
      flex: 0 0 75%;
      position: relative;
      padding: 0 15px 200px;

    }
    @media (max-width:768px) {
        .left {
          display: none;
        }
        .right {
          flex: 1;
          padding: 0 15px 40px;
          .bottom {
            display: block;
            .bottom-lt {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
  }
}
</style>
